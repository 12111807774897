import React from "react";
import {
  CContainer,
  CFooter,
  CRow,
  CCol,
  CImg,
  CNav,
  CNavItem,
  CNavLink,
} from "@coreui/react";
import logo from "../assets/images/logo.png";
import { useTranslation } from "react-i18next";
import config from "../config";
const TheFooter = () => {
  const { t } = useTranslation();
  return (
    <CFooter className="footer">
      <CContainer fluid className="grid">
        <CRow>
          <CCol xl="8" lg="9" md="12" xs="12" className="menu">
            <CRow>
              <CCol xs="12" lg="12" md="12" className="pl-0 footer-menu-col">
                <CNav className="nav">
                  <CNavItem className="item">
                    <CNavLink to={config.routes.products} className="link">
                      {t("Products")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem className="item">
                    <CNavLink to={config.routes.aiDesign} className="link">
                      {t("AI Design & Development")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem className="item">
                    <CNavLink
                      to={config.routes.athenaResearch}
                      className="link"
                    >
                      {t("Athen Research Center")}
                    </CNavLink>
                  </CNavItem>

                  <CNavItem className="item">
                    <CNavLink to={config.routes.imprint} className="link">
                      {t("Imprint")}
                    </CNavLink>
                  </CNavItem>
                  <CNavItem className="item">
                    <CNavLink to={config.routes.privacy} className="link">
                      {t("Privacy")}
                    </CNavLink>
                  </CNavItem>
                </CNav>
              </CCol>
            </CRow>
          </CCol>
          <CCol xl="2" lg="3" xs="12" md={12} className="logo">
            {" "}
            <CImg className="img" src={logo} />
          </CCol>
          <CCol xl="2" lg="2" xs="12" md={12} className="copyright p-0">
            <p>© 2022 boot.AI</p>
          </CCol>
        </CRow>
      </CContainer>
    </CFooter>
  );
};

export default React.memo(TheFooter);
