import React, { useState, useEffect } from "react";
import classNames from "classnames";
import {
  CNavbar,
  CNavLink,
  CNavbarBrand,
  CNavbarNav,
  CContainer,
  CNavItem,
  CImg,
  CFade,
  CButton,
} from "@coreui/react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/logo.png";
import flag_de from "../assets/flags/globe.png";
import config from "../config";
import menu from "../assets/images/mobile-image/Menu.svg";
import times from "../assets/images/mobile-image/Vector.svg";
import { Link } from "react-router-dom";

const current = localStorage.getItem("i18nextLng") || "de";

const Nav = () => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [activeClass, setActiveClass] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener("scroll", mouseDown);
    return () => {
      window.removeEventListener("scroll", mouseDown);
    };
  }, []);

  const mouseDown = () => {
    if (window.scrollY > 100) {
      setActiveClass("navbar-shrink");
    } else {
      setActiveClass("");
    }
  };
  const [language, setlanguage] = useState({
    flag: flag_de,
    name: current === "en" ? "English" : "German",
    language: current,
  });
  const handleClick = (language) => {
    if (language.language === "en") {
      i18next.changeLanguage("de");

      setlanguage({ flag: flag_de, name: "German", language: "de" });
    } else {
      i18next.changeLanguage("en");

      setlanguage({ flag: flag_de, name: "English", language: "en" });
    }
  };
  const mouseScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // const [navbar__mobile, activeNavbar__mobile] = useState(false);

  return (
    <>
      <CNavbar
        expandable="sm"
        className={`navbar navbar-expand-lg navbar-dark fixed-top navbar__desktop  ${activeClass}`}
        id="mainNav"
      >
        <CContainer fluid className="grid p-0">
          <CNavLink
            className="js-scroll-trigger text-uppercase"
            onClick={() => {
              mouseScroll();
            }}
          >
            <CNavbarBrand to="/">
              <CImg src={logo} />{" "}
            </CNavbarBrand>{" "}
          </CNavLink>
          <CNavbarNav className="show_header">
            <CNavItem>
              <CFade>
                <CNavLink
                  exact
                  className="js-scroll-trigger text-uppercase navbar__link"
                  to="/"
                >
                  {t("HOME")}{" "}
                </CNavLink>{" "}
                <div className="nav_underlined"> </div>{" "}
              </CFade>{" "}
            </CNavItem>{" "}
            <CNavItem>
              <CFade>
                <CNavLink
                  exact
                  className="js-scroll-trigger text-uppercase navbar__link"
                  to={config.routes.aboutUs}
                >
                  {t("ABOUT US")}{" "}
                </CNavLink>{" "}
                <div className="nav_underlined"> </div>{" "}
              </CFade>{" "}
            </CNavItem>{" "}
            <CNavItem>
              <CFade>
                {" "}
                <CNavLink
                  className="js-scroll-trigger text-uppercase navbar__link"
                  to={config.routes.aiDesign}
                >
                  {t("AI DESIGN & AI DEVELOPMENT")}{" "}
                </CNavLink>{" "}
                <div className="nav_underlined"> </div>{" "}
              </CFade>{" "}
            </CNavItem>{" "}
            <CNavItem>
              <CFade>
                {" "}
                <CNavLink
                  className="js-scroll-trigger text-uppercase navbar__link"
                  to={config.routes.products}
                >
                  {t("PRODUCTS")}{" "}
                </CNavLink>{" "}
                <div className="nav_underlined"> </div>{" "}
              </CFade>{" "}
            </CNavItem>{" "}
            <CNavItem>
              <CFade>
                {" "}
                <CNavLink
                  className="js-scroll-trigger text-uppercase navbar__link"
                  to={config.routes.athenaResearch}
                >
                  {t("ATHENA RESEARCH CENTER")}{" "}
                </CNavLink>{" "}
                <div className="nav_underlined"> </div>{" "}
              </CFade>{" "}
            </CNavItem>
            <CNavItem>
              <CFade>
                {" "}
                <CNavLink
                  className="js-scroll-trigger text-uppercase navbar__link"
                  to={config.routes.contactUs}
                >
                  {t("CONTACT US")}{" "}
                </CNavLink>{" "}
                <div className="nav_underlined"> </div>{" "}
              </CFade>{" "}
            </CNavItem>{" "}
            <div className="language" onClick={() => handleClick(language)}>
              <CButton className="language__toggle">
                <div
                  className="logo"
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={language.flag}
                    alt="IMG"
                    style={{ width: "100%" }}
                  />
                </div>{" "}
                <div
                  className="text"
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {language.name}{" "}
                </div>{" "}
              </CButton>{" "}
              {/* <CDropdownMenu>
                            <CDropdownItem onClick={() => handleClick({'flag':flag_vn,'name':'Việt Nam', 'language': 'vn'})}>
                              <span>Việt Nam</span>
                            </CDropdownItem>
                            <CDropdownItem onClick={() => handleClick({'flag':flag_en,'name':'English', 'language': 'en'})}>
                              <span>English</span>
                            </CDropdownItem>
                            <CDropdownItem onClick={() => handleClick({'flag':flag_de,'name':'German', 'language': 'ge'})}>
                              <span>German</span>
                              </CDropdownItem>
                          </CDropdownMenu> */}{" "}
            </div>{" "}
          </CNavbarNav>{" "}
        </CContainer>{" "}
      </CNavbar>
      <div className="nav__mobile">
        <Link to="/" onClick={() => mouseScroll()}>
          <img src={logo} alt="IMG" className="nav__mobile-logo" />
        </Link>{" "}
        <label
          htmlFor="nav-mobile-input"
          className="nav__bars-btn"
          onClick={() => setIsNavbarOpen(true)}
        >
          <img src={menu} alt="IMG" />
        </label>
        <label
          htmlFor="nav-mobile-input"
          className={classNames("nav__overlay", {
            "nav__overlay--open": isNavbarOpen,
          })}
          onClick={() => setIsNavbarOpen(false)}
        ></label>{" "}
        <input type="checkbox" id="nav-mobile-input" className="nav__input" />
      </div>
      <CNavbar
        className={classNames("navbar__mobile", {
          "navbar__mobile--open": isNavbarOpen,
        })}
      >
        <label
          htmlFor="nav-mobile-input"
          className="nav__mobile-close"
          onClick={() => setIsNavbarOpen(false)}
        >
          <img alt="IMG" src={times} />{" "}
        </label>{" "}
        <CNavbarNav className="navbar__mobile-list">
          <CNavItem>
            <CFade>
              <CNavLink
                exact
                className="js-scroll-trigger text-uppercase navbar__mobile-link"
                to="/"
                onClick={() => {
                  setIsNavbarOpen(false);
                }}
              >
                {t("HOME")} <div className="nav_underlined"> </div>{" "}
              </CNavLink>{" "}
            </CFade>{" "}
          </CNavItem>{" "}
          <CNavItem>
            <CFade>
              <CNavLink
                exact
                className="js-scroll-trigger text-uppercase navbar__mobile-link"
                to={config.routes.aboutUs}
                onClick={() => {
                  setIsNavbarOpen(false);
                }}
              >
                {t("ABOUT US")} <div className="nav_underlined"> </div>{" "}
              </CNavLink>{" "}
            </CFade>{" "}
          </CNavItem>{" "}
          <CNavItem>
            <CFade>
              {" "}
              <CNavLink
                className="js-scroll-trigger text-uppercase navbar__mobile-link"
                to={config.routes.aiDesign}
                onClick={() => {
                  setIsNavbarOpen(false);
                }}
              >
                {t("AI DESIGN & AI DEVELOPMENT")}{" "}
                <div className="nav_underlined"> </div>{" "}
              </CNavLink>{" "}
            </CFade>{" "}
          </CNavItem>{" "}
          <CNavItem>
            <CFade>
              {" "}
              <CNavLink
                className="js-scroll-trigger text-uppercase navbar__mobile-link"
                to={config.routes.products}
                onClick={() => {
                  setIsNavbarOpen(false);
                }}
              >
                {t("PRODUCTS")} <div className="nav_underlined"> </div>{" "}
              </CNavLink>{" "}
            </CFade>{" "}
          </CNavItem>{" "}
          <CNavItem>
            <CFade>
              {" "}
              <CNavLink
                className="js-scroll-trigger text-uppercase navbar__mobile-link"
                to={config.routes.athenaResearch}
                onClick={() => {
                  setIsNavbarOpen(false);
                }}
              >
                {t("ATHENA RESEARCH CENTER")}{" "}
                <div className="nav_underlined"> </div>{" "}
              </CNavLink>{" "}
            </CFade>{" "}
          </CNavItem>
          <CNavItem>
            <CFade>
              {" "}
              <CNavLink
                className="js-scroll-trigger text-uppercase navbar__mobile-link"
                to={config.routes.contactUs}
                onClick={() => {
                  setIsNavbarOpen(false);
                }}
              >
                {t("CONTACT US")} <div className="nav_underlined"> </div>{" "}
              </CNavLink>{" "}
            </CFade>{" "}
          </CNavItem>{" "}
          <CNavItem>
            <div className="language" onClick={() => handleClick(language)}>
              <CButton className="language__toggle">
                <div
                  className="logo"
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={language.flag}
                    alt="IMG"
                    style={{ width: "100%" }}
                  />
                </div>{" "}
                <div
                  className="text"
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {language.name}{" "}
                </div>{" "}
              </CButton>{" "}
            </div>{" "}
          </CNavItem>{" "}
        </CNavbarNav>
        <CNavbarBrand className="navbar__mobile-logo" to="/">
          <CImg src={logo} width="100%" />
        </CNavbarBrand>{" "}
      </CNavbar>{" "}
    </>
  );
};

export default Nav;
