import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en.json";
import translationDE from "./locales/de.json";

const detectLang = localStorage.getItem("i18nextLng");

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      de: {
        translation: translationDE,
      },
    },
    lng: detectLang || "de",
    fallbackLng: "de",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
