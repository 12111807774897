import React, { useEffect, useState } from "react";
import { CModal, CModalBody, CButton, CRow, CCol } from "@coreui/react";
import { useTranslation } from "react-i18next";
import config from "../../config";

const ModalPrivacy = () => {
  const { t } = useTranslation();

  const [viewPopup, setViewPopup] = useState(false);

  useEffect(() => {
    let visited = localStorage["alreadyVisited"];
    if (visited) {
      setViewPopup(false);
      //do not view Popup
    } else {
      //this is the first time
      localStorage["alreadyVisited"] = true;
      setViewPopup(true);
    }
  }, []);

  return (
    <CModal
      show={viewPopup}
      backdrop={false}
      closeOnBackdrop={false}
      centered={true}
      style={{
        position: "fixed",
        bottom: "32px",
        width: "750px",
        color: "#fff",
        fontSize: "12px",
        lineHeight: "150%",
        borderRadius: 8,
      }}
      addContentClass="modal__wrapper"
    >
      <CModalBody style={{ padding: "1.5rem" }}>
        <CRow>
          <CCol md={9} sm={12} col={12}>
            {t("Privacy noti")}{" "}
            <a
              style={{ color: "#1F9E57", textDecoration: "underline" }}
              href={config.routes.privacy}
            >
              {t("Learn more 2")}{" "}
            </a>{" "}
          </CCol>{" "}
          <CCol md={3} sm={12} col={12} className="popup__button">
            <CButton
              onClick={() => setViewPopup(false)}
              style={{
                borderRadius: "32px",
                backgroundColor: "#1F9E57",
                padding: "6px 20px",
                color: "#fff",
                fontWeight: "bold",
                fontSize: 14,
                lineHeight: "20px",
                width: "100%",
                height: "50px",
              }}
              className="popup__button--mobile"
            >
              {t("Got it")}
            </CButton>{" "}
          </CCol>{" "}
        </CRow>{" "}
      </CModalBody>{" "}
    </CModal>
  );
};

export default ModalPrivacy;
