import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./containers/ScrollToTop";
import Navbar from "./containers/Navbar";
import Footer from "./containers/TheFooter";
import "./scss/style.scss";
import config from "./config";
import "./scss/semantic.css";
import ModalPrivacy from "./views/privacy/ModalPrivacy";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"> </div>{" "}
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const AboutUs = React.lazy(() => import("./about-us/TheLayout"));
const AthenaResearchCenter = React.lazy(() =>
  import("./athena-research-center/TheLayout")
);
const DesignAI = React.lazy(() => import("./design-AI/Layout"));
const Product = React.lazy(() => import("./product/TheLayout"));
const ContactUs = React.lazy(() => import("./contact-us/TheLayout"));
const Imprint = React.lazy(() => import("./imprint/TheLayout"));
const Privacy = React.lazy(() => import("./privacy/TheLayout"));

const App = () => {
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop />
        <React.Suspense fallback={loading}>
          <ModalPrivacy />
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route>
              <div className="c-app c-dark-theme">
                <div className="c-wrapper">
                  <React.Suspense fallback={loading}>
                    <Navbar />

                    <Switch>
                      <Route
                        path={config.routes.aboutUs}
                        name="AboutUs"
                        render={(props) => <AboutUs {...props} />}
                      />
                      <Route
                        path={config.routes.athenaResearch}
                        name="AthenaResearchCenter"
                        render={(props) => <AthenaResearchCenter {...props} />}
                      />
                      <Route
                        path={config.routes.aiDesign}
                        name="Design-AI"
                        render={(props) => <DesignAI {...props} />}
                      />
                      <Route
                        path={config.routes.products}
                        name="Product"
                        render={(props) => <Product {...props} />}
                      />
                      <Route
                        path={config.routes.contactUs}
                        name="contact-us"
                        render={(props) => <ContactUs {...props} />}
                      />
                      <Route
                        path={config.routes.imprint}
                        name="imprint"
                        render={(props) => <Imprint {...props} />}
                      />
                      <Route
                        path={config.routes.privacy}
                        name="privacy"
                        render={(props) => <Privacy {...props} />}
                      />
                      <Route path="/" name="Home" component={TheLayout} />{" "}
                    </Switch>

                    <Footer />
                  </React.Suspense>{" "}
                </div>{" "}
              </div>{" "}
            </Route>{" "}
          </Switch>{" "}
        </React.Suspense>{" "}
      </Router>{" "}
    </React.Fragment>
  );
};

export default App;
