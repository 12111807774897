const config = {
  app: {},
  rest: {},
  routes: {
    aboutUs: "/about-us",
    aiDesign: "/ai-design",
    products: "/products",
    athenaResearch: "/athenaResearch",
    contactUs: "/contact-us",
    imprint: "/imprint",
    privacy: "/privacy",
  },
};

export default config;
